export const fields = {
  country: {
    label: 'Country*',
    rules: 'required',
  },
  permitType: {
    label: 'Permit Type*',
    rules: 'required',
  },
};
