import React, { useEffect } from 'react';
import { Settings, CoreModule, ExecuteRule } from './Modules';
import { IBaseModuleProps } from '@wings/shared';
import { Routes, Route } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { sidebarOptions, usePermitModuleSecurity } from './Modules/Shared';
import { PermitRoutes } from './Modules/Core/Components';
import { NotFoundPage, SearchStore } from '@wings-shared/core';
import { ProtectedRoute, SidebarStore } from '@wings-shared/layout';

const PermitsApp = (props: IBaseModuleProps) => {
  const redirectPath: string = '/permits';
  const permitModuleSecurity = usePermitModuleSecurity();
  const _sidebarStore = props.sidebarStore as typeof SidebarStore;

  /* istanbul ignore next */
  useEffect(() => {
    _sidebarStore?.setNavLinks(sidebarOptions(false), props.basePath)
    return () => {
      SearchStore.clearSearch();
    };
  }, []);

  return (
    <Routes>
      <Route path="permits/*">
        <Route index element={<CoreModule />} />
        <Route path="settings" element={<Settings />} />
        <Route path="executeRules" element={<ExecuteRule />} />
        <Route
          path=":viewMode/*"
          key="permitAdd"
          element={
            <ProtectedRoute
              element={<PermitRoutes key={'permit-add'} />}
              redirectPath={redirectPath}
              hasPermission={permitModuleSecurity.isEditable}
            />
          }
        />
        <Route path=":permitId/:viewMode/*" element={<PermitRoutes key={'permit-edit'} />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};

export default inject('sidebarStore')(observer(PermitsApp));
