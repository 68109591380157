export const fields = {
  isException: {
    label: 'Is Exception',
  },
  exception: {
    label: 'Exception',
    rules: 'between:1,2000',
    disabled: true,
  },
  permitExceptionRules: {
    value: [],
  },
};
