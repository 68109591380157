export enum CONDITION_EDITOR {
  // SelectControl Fields
  AIRPORT_OF_ENTRY = 'AirportOfEntry',
  MILITARY_AIRPORT = 'MilitaryAirport',
  IS_CARGO_DISEMBARKATION = 'IsCargoDisembarkation',
  IS_CARGO_EMBARKATION = 'IsCargoEmbarkation',
  IS_CARGO_FLIGHT = 'IsCargoFlight',
  IS_FLIGHT_NUMBER_EXISTS = 'IsFlightNumberExists',
  IS_PAX_DISEMBARKATION = 'IsPaxDisembarkation',
  IS_PAX_EMBARKATION = 'IsPaxEmbarkation',
  IS_RON = 'IsRon',
  IS_TECH_STOP = 'IsTechStop',

  // AutoComplete Fields
  AIRPORT = 'Airport',
  STATE = 'State',
  COUNTRY = 'Country',
  REGION = 'Region',
  FIR = 'FIR',
  FARTYPE = 'FARType',
  PURPOSE_OF_Flight = 'PurposeOfFlight',
  ICAO_AERODROME_REFERENCE_CODE = 'ICAOAerodromeReferenceCode',
  NOISE_CHAPTER = 'NoiseChapter',
  AIRCRAFT_CATEGORY = 'AircraftCategory',
  CROSSING_TYPE = 'CrossingType',
  REGISTRATION_NATIONALITY_REGION = 'RegistrationNationalityRegion',
  AIRPORTOFENTRY_AOE = 'AirportOfEntry_AOE',
  ARRIVAL_AIRPORT = 'ArrivalAirport',
  DEPARTURE_AIRPORT = 'DepartureAirport',
  DEPARTURE_REGION = 'DepartureRegion',
  ARRIVAL_STATE = 'ArrivalState',
  NEXT_DESTINATION_AIRPORT = 'NextDestinationAirport',
  NEXT_DESTINATION_REGION = 'NextDestinationRegion',
  OPERATOR_NATIONALITY_REGION = 'OperatorNationalityRegion',
  //Country AutoComplete
  REGISTRATION_NATIONALITY = 'RegistrationNationality',
  REGISTRATION_NATIONALITY_SOVEREIGNTY = 'RegistrationNationalitySovereignty',
  ARRIVAL_COUNTRY = 'ArrivalCountry',
  COUNTRIES_OVERFLOWN = 'CountriesOverflown',
  COUNTRY_FIRS_OVERFLOWN = 'CountryFIRsOverflown',
  COUNTRY_POLYGONS_OVERFLOWN = 'CountryPolygonsOverflown',
  DEPARTURE_COUNTRY = 'DepartureCountry',
  NEXT_DESTINATION_COUNTRY = 'NextDestinationCountry',
  OPERATOR_NATIONALITY = 'OperatorNationality',

  // Integer fields
  MTOW_KGS = 'MTOWKgs',
  MTOW_LBS = 'MTOWLbs',
  MTOW_METRIC_TONS = 'MTOWMetricTons',
  PASSENGER_SEAT_CAPACITY = 'PassengerSeatCapacity',
  FLIGHT_LEVEL_IN_COUNTRY = 'FlightLevelInCountry',
  MACHSPEED = 'MachSpeed',
  PASSENGER_COUNT = 'PassengerCount',
  TOG = 'TOG',
}
