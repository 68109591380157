export enum PERMIT_RULE_SOURCES {
  Airport = 'Airport',
  State = 'State',
  Country = 'Country',
  Region = 'Region',
  FIR = 'FIR',
  FARType = 'FARType',
  PurposeOfFlight= 'PurposeOfFlight',
  ICAOAerodromeReferenceCode = 'ICAOAerodromeReferenceCode',
  NoiseChapter = 'NoiseChapter',
  AircraftCategory = 'AircraftCategory',
  CrossingType = 'CrossingType',
  AirportOfEntry_AOE= 'AirportOfEntry',
}
