import { ISelectOption } from '@wings-shared/core';
import { CONDITION_EDITOR } from '../../../Shared';

export const fields = {
  permitRequiredElements: {
    label: 'Element',
    value: [],
  },
};

export const booleanOptions: ISelectOption[] = [
  { label: 'True', value: 1 },
  { label: 'False', value: 2 },
];

export const integerFields = [
  CONDITION_EDITOR.MTOW_KGS,
  CONDITION_EDITOR.MTOW_LBS,
  CONDITION_EDITOR.MTOW_METRIC_TONS,
  CONDITION_EDITOR.PASSENGER_SEAT_CAPACITY,
  CONDITION_EDITOR.FLIGHT_LEVEL_IN_COUNTRY,
  CONDITION_EDITOR.MACHSPEED,
  CONDITION_EDITOR.PASSENGER_COUNT,
  CONDITION_EDITOR.TOG,
].map(x => x.toString());

export const selectOptionFields = [
  CONDITION_EDITOR.AIRPORT_OF_ENTRY,
  CONDITION_EDITOR.MILITARY_AIRPORT,
  CONDITION_EDITOR.IS_CARGO_DISEMBARKATION,
  CONDITION_EDITOR.IS_CARGO_EMBARKATION,
  CONDITION_EDITOR.IS_CARGO_FLIGHT,
  CONDITION_EDITOR.IS_FLIGHT_NUMBER_EXISTS,
  CONDITION_EDITOR.IS_PAX_DISEMBARKATION,
  CONDITION_EDITOR.IS_PAX_EMBARKATION,
  CONDITION_EDITOR.IS_RON,
  CONDITION_EDITOR.IS_TECH_STOP,
].map(x => x.toString());
